<template>
  <v-container>
    <template>
        <v-row class="fill-height">
            <v-col>
            <v-sheet class="calendarSizing" height="65">
                <v-toolbar
                flat
                >
                <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                >
                    Today
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                    bottom
                    right
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                        mdi-menu-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateEvent"
                ></v-calendar>
                <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                max-width="500px"
                >
                <v-card
                    color="grey lighten-4"
                    min-width="300px"
                    flat
                >
                    <v-toolbar
                    :color="selectedEvent.color"
                    dark
                    dense
                    >
                    <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon 
                        @click="selectedOpen = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar>
                    <v-card-text v-if="selectedEvent.type == 'Leave'">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-calendar-cursor</v-icon>
                                        <span>Submission Date</span>
                                    </td>
                                    <td>
                                        <span>{{ convertDateTime(selectedEvent.dateapplied) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-calendar-today</v-icon>
                                        <span>Start Date</span>
                                    </td>
                                    <td>
                                        <span>{{ convertDateTime(selectedEvent.start) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-calendar</v-icon>
                                        <span>End Date</span>
                                    </td>
                                    <td>
                                        <span>{{ convertDateTime(selectedEvent.end) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-calendar-range</v-icon>
                                        <span>No of Days</span>
                                    </td>
                                    <td>
                                        <span>{{ selectedEvent.countused }} days</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-check-decagram</v-icon>
                                        <span>Leave Status</span>
                                    </td>
                                    <td>
                                        <span 
                                        v-if="selectedEvent.leavestatus == 'PENDING'" 
                                        class="orange--text">
                                            <strong>{{ selectedEvent.leavestatus }}</strong>
                                        </span>
                                        <span 
                                        v-else-if="selectedEvent.leavestatus == 'APPROVED'" 
                                        class="green--text">
                                            <strong>{{ selectedEvent.leavestatus }}</strong>
                                        </span>
                                        <span 
                                        v-else-if="selectedEvent.leavestatus == 'REJECTED'" 
                                        class="red--text">
                                            <strong>{{ selectedEvent.leavestatus }}</strong>
                                        </span>
                                        <span v-else class="pink--text text--darken-2">
                                            {{ selectedEvent.leavestatus }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-calendar-today</v-icon>
                                        <span>Start Date</span>
                                    </td>
                                    <td>
                                        <span>{{ convertDateString(selectedEvent.start.split('T')[0]) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-icon class="mr-2" small>mdi-calendar</v-icon>
                                        <span>End Date</span>
                                    </td>
                                    <td>
                                        <span>{{ convertDateString(selectedEvent.end.split('T')[0]) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <!-- <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                    >
                        Cancel
                    </v-btn>
                    </v-card-actions> -->
                </v-card>
                </v-menu>
            </v-sheet>
            </v-col>
        </v-row>
    </template>
    <!-- Dialog for Leave Category -->

    <template>
        <v-dialog
        v-model="categoryLeaveDialog"
        width="600"
        scrollable
        >
        <v-card>
            <v-card-title class="justify-center title-bar-modul">
                <h4>Leave Category</h4>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        align="center"
                    >
                        <v-btn
                            color="primary"
                            outlined
                            @click="categoryLeaveDialog = false; applyLeaveDialog = true; applyLeaveType = 'Annual Leave'; leaveData.leavetype = 1; countDaysApplied()"
                        >
                            Annual/Medical Leave
                        </v-btn>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        align="center"
                    >
                        <v-btn
                            color="primary"
                            outlined
                            @click="categoryLeaveDialog = false; applyLeaveDialog = true; applyLeaveType = 'Other Leave'; leaveData.leavetype = '3'; countDaysOtherLeave()"
                        >
                            Other Leave
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
    </template>
    <!-- End Dialog for Leave Category -->

    <!-- Dialog for Annual / Other Leave -->
    <template>
        <v-dialog
        v-model="applyLeaveDialog"
        width="600"
        scrollable
        >
        <v-form
            ref="anualLeaveForm"
            v-model="valid"
            lazy-validation
        >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4 v-if="applyLeaveType == 'Annual Leave'">Annual Leave Form / Medical Leave Form</h4>
                    <h4 v-else-if="applyLeaveType == 'Other Leave'">Other Leave Form</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <template v-if="applyLeaveType == 'Annual Leave'">
                            <v-col
                                class="d-flex pt-5"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                :items="selectLeaveType"
                                item-text="name"
                                item-value="idleavetype"
                                v-model="leaveData.leavetype"
                                label="Leave Type"
                                dense
                                outlined
                                class="ma-0 pa-0"
                                hide-details="auto"
                                :rules="[v => !!v || 'Please choose leave type']"

                                ></v-select>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="4"
                            >
                                <v-menu
                                v-model="modalCalendarFromAnnual"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="valueFromDate"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        hide-details=""

                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="valueFromDate"
                                        v-on:change="selectFromDate1;valueToDate = valueFromDate; countDaysApplied();"
                                        @input="modalCalendarFromAnnual = false"
                                    >
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="3"
                            >
                                <v-checkbox
                                v-model="checkboxHalfday1"
                                :label="`Halfday?`"
                                v-on:change="countDaysApplied()"
                                hide-details="auto"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="5"
                            >
                                <v-select
                                v-show="checkboxHalfday1"
                                v-if="checkboxHalfday1 == true"
                                v-model="valueFromHalfday"
                                :items="valueFromDate == valueToDate ? halfdaySelect : halfdaySelectPM"
                                menu-props="auto"
                                label="Session"                                    
                                dense
                                outlined
                                hide-details="auto"
                                prepend-icon="mdi-clock"
                                single-line
                                class="mt-2"
                                :rules="[v => !!v || 'Please choose leave session']"
                                ></v-select>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="4"
                            >
                                <v-menu
                                v-model="modalCalendarToAnnual"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="valueToDate"
                                        label="End Date"
                                        prepend-icon="mdi-calendar"
                                        hide-details=""

                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="valueToDate"
                                        v-on:change="selectToDate1;countDaysApplied()"
                                        @input="modalCalendarToAnnual = false"
                                        :min="valueFromDate"
                                    >
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="3"
                                v-if="valueFromDate != valueToDate"
                            >
                                <v-checkbox
                                v-model="checkboxHalfday2"
                                :label="`Halfday?`"
                                hide-details="auto"
                                v-on:change="countDaysApplied()"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="5"
                            >
                                <v-select
                                v-show="checkboxHalfday2"
                                v-if="checkboxHalfday2 == true"
                                v-model="valueToHalfday"
                                :items="valueFromDate == valueToDate ? halfdaySelect : halfdaySelectAM"
                                menu-props="auto"
                                label="Session"                                    
                                dense
                                outlined
                                hide-details="auto"
                                prepend-icon="mdi-clock"
                                class="mt-2"
                                :rules="[v => !!v || 'Please choose leave session']"
                                ></v-select>
                                <!-- <v-select
                                :items="halfdaySelect"
                                label="Session"
                                dense
                                outlined
                                class="ma-0 pa-0"
                                hide-details="auto"
                                ></v-select> -->
                            </v-col>
                        </template>

                        <template v-else-if="applyLeaveType == 'Other Leave'">
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="12"
                            >
                                <v-radio-group v-model="leaveData.leavetype" hide-details="auto" :rules="[v => !!v || 'Please choose your leave type']" >
                                    <template v-slot:label>
                                        <div>Category</div>
                                    </template>
                                    <v-radio value="3" @change="countDaysOtherLeave(3)" >
                                        <template v-slot:label>
                                        <div>Bereavement Leave</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="4" @change="countDaysOtherLeave(4)" v-if="$store.getters.user.maritalStatus == 'Single' || $store.getters.user.maritalStatus == null">
                                        <template v-slot:label>
                                        <div>Marriage Leave</div>
                                        </template>
                                    </v-radio>
                                    <span v-if="$store.getters.user.maritalStatus != 'Single'">
                                        <v-radio value="5" @change="countDaysOtherLeave(5)" v-if="$store.getters.user.gender == 'M'">
                                            <template v-slot:label>
                                            <div>Paternity Leave</div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="6" @change="countDaysOtherLeave(6)" v-if="$store.getters.user.gender == 'F'">
                                            <template v-slot:label>
                                            <div>Maternity Leave <span class="grey--text">(days encounted including weekends and public holidays)</span> </div>
                                            </template>
                                        </v-radio>
                                    </span>
                                    <v-radio value="7" @change="countDaysOtherLeave(7)">
                                        <template v-slot:label>
                                        <div>Hospitalization Leave <span class="grey--text">(original Discharge Note and Medical Certificate must be presented to HR and uploaded)</span></div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="8" @change="countDaysOtherLeave(8)">
                                        <template v-slot:label>
                                        <div>Replacement Leave <span class="grey--text">(leave application to replace your workday during holiday)</span></div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="12"
                            >
                                <v-menu
                                v-model="modalCalendarFromOther"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="valueFromDate"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        hide-details

                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="valueFromDate"
                                        v-on:change="countDaysOtherLeave();"
                                        @input="modalCalendarFromOther = false"
                                    >
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="12"
                            >
                                <v-menu
                                v-model="modalCalendarToOther"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="valueToDate"
                                        label="End Date"
                                        prepend-icon="mdi-calendar"
                                        hide-details

                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="valueToDate"
                                        v-on:change="countDaysApplied()"
                                        @input="modalCalendarToOther = false"
                                        :min="valueFromDate"
                                        :max="valueMaxDate"
                                    >
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </template>
                        
                        <v-col
                            class="d-flex"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="leaveData.countused"
                                outlined
                                label="No of Days Applied"
                                dense
                                hide-details="auto"
                                readonly
                                :rules="[v => (parseFloat(v) && v > 0) || 'Please choose the end date']"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="leaveData.address"
                                outlined
                                label="Address During Leave"
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter your address']"
                                maxlength="200"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="leaveData.reason"
                                outlined
                                label="Reasons"
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter your reason']"
                                maxlength="500"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex"
                            cols="12"
                            sm="10"
                        >
                            <v-file-input
                                v-if="applyLeaveType == 'Annual Leave' && leaveData.leavetype == '2'"
                                label="Medical Certificate"
                                outlined
                                dense
                                :rules="[v => !!v || 'Please upload your Medical Certificate']"
                                @change="fileUploadOnChange($event)"
                            ></v-file-input>
                            <v-file-input
                                v-else-if="applyLeaveType == 'Other Leave'"
                                label="Supporting Document"
                                outlined
                                dense
                                @change="fileUploadOnChange($event)"
                            ></v-file-input>
                        </v-col>
                        <v-col
                            v-if="fileUploadProgress == true" 
                            class="d-flex" 
                            cols="12"
                            sm="2"
                        >
                            <v-progress-circular 
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card-text>
                    
                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    outlined
                    @click="applyLeaveDialog = false; resetForm()"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    @click="submitLeaveForm()"
                >
                    Apply
                </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-form>
        </v-dialog>
    </template>
    <!-- End Dialog for Annual / Other Leave -->

    <template>
        <div class="text-center">
            <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="messagecolor"
            >
            {{ messagetext }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
            </v-snackbar>
        </div>
    </template>
  </v-container>
  
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
  data: () => ({
      
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
          {
              leave_type: 'ANNUAL LEAVE',
              color: 'indigo'
          },
          {
              leave_type: 'MEDICAL LEAVE',
              color: 'cyan'
          },
          {
              leave_type: 'BEREAVEMENT LEAVE',
              color: 'light-blue'
          },
          {
              leave_type: 'MARRIAGE LEAVE',
              color: 'teal'
          },
          {
              leave_type: 'PATERNITY LEAVE',
              color: 'light-green'
          },
          {
              leave_type: 'HOSPITALIZATION LEAVE',
              color: 'blue-grey'
          },
          {
              leave_type: 'REPLACEMENT LEAVE',
              color: 'green darken-2'
          },
          {
              leave_type: 'CANCELLED LEAVE',
              color: 'pink darken-2'
          },
      ],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

      // Dialog
      categoryLeaveDialog : false,
      applyLeaveDialog : false,
      otherLeaveDialog : false,
      
      applyLeaveType: null,
      radios: 'leave type',
      leaveType: ['Annual Leave', 'Medical Leave'],
      halfdaySelect: ['AM (8.30am - 01.00pm)','PM (1.00pm - 05.00pm)'],
      halfdaySelectAM: ['AM (8.30am - 01.00pm)'],
      halfdaySelectPM: ['PM (1.00pm - 05.00pm)'],
      modalCalendarFromAnnual: false,
      modalCalendarToAnnual: false,
      modalCalendarFromOther: false,
      modalCalendarToOther: false,
      valueFromDate : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      valueToDate : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      selectFromDate1: '', 
      selectToDate1: '', 
      selectFromDate3: '', 
      selectFromDate4: '', 
      checkboxHalfday1: false,
      checkboxHalfday2: false,
      valueFromHalfday: '',
      valueToHalfday: '',
      selectLeaveType: [],

      leaveData : {
        idemp: '',
        dateapplied: '',
        leavetype: '',
        startdate: '',
        enddatetime: '',
        countused: '1',
        address: '',
        reason: '',
        fileupload: ''
      },

      snackbar: false,
      timeout: 3000,
      messagecolor: '',
      messagetext: '',

      valueMaxDate: '',
      
    //   form validation
      valid: true,
      fileUploadProgress: false,

    }),
    mounted () {
      this.$refs.calendar.checkChange();
      this.getAPIDropdown()
    },
    methods: {
      viewDay ({ date }) {
        this.focus = date;
        this.valueFromDate = date;
        this.valueToDate = date;
        // this.countDaysApplied();
        this.categoryLeaveDialog = true;

        // var today = new Date().toLocaleDateString();

        // if(today <= new Date(date).toLocaleDateString()){
        //     this.categoryLeaveDialog = true;
        // }

        // console.log(date);
        // this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      changeColor(leave_type){
          let colors = this.colors;
          for (let i = 0; i < colors.length; i++){
              if(colors[i].leave_type == leave_type){
                  return colors[i].color;
              }
          }
      },
      updateEvent () {
        let that = this;
        let events = [];

        axios.get(process.env.VUE_APP_API_URL + 'cuti/publicholiday', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {
            const data = response.data;

            for(let i = 0; i < data.length; i++){
                events.push(data[i]);
            }
            
        })
        .catch(error => {
            console.log(error);
        });

        axios.get(process.env.VUE_APP_API_URL + 'cuti/all?self=1', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {
            const data = response.data;

            for(let i = 0; i < data.length; i++){
                if(data[i].leavestatus == 'CANCELLED'){
                    let objEvent = {
                        name: data[i].leavetype + ' ' + '(CANCELLED)',
                        start: data[i].startdate,
                        end: data[i].enddatetime,
                        color: that.changeColor('CANCELLED LEAVE'),
                        timed: true,
                        leavestatus: data[i].leavestatus,
                        dateapplied: data[i].dateapplied, 
                        countused: data[i].countused,
                        type: 'Leave' 
                    }
                    events.push(objEvent);
                } else {
                    let objEvent = {
                        name: data[i].leavetype,
                        start: data[i].startdate,
                        end: data[i].enddatetime,
                        color: that.changeColor(data[i].leavetype),
                        timed: true,
                        leavestatus: data[i].leavestatus,
                        dateapplied: data[i].dateapplied, 
                        countused: data[i].countused,
                        type: 'Leave' 
                    }
                    events.push(objEvent);
                } 
                
                
            }
            
            this.events = events;
            
        })
        .catch(error => {
            console.log(error);
        });

        

      },

      getAPIDropdown(){
        axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {
            // console.log(response.data);
            const leave_type = response.data.leavetype;

            for(let i = 0; i < leave_type.length; i++){
                if(leave_type[i].idleavetype == 1 || leave_type[i].idleavetype == 2){
                    this.selectLeaveType.push(leave_type[i]);
                }
            }
            
        })
        .catch(error => {
            console.log(error);
        });
      },

      countDaysApplied(){

        let halfdayFrom = (this.checkboxHalfday1 == true) ? '0.5' : '0';
        let halfdayTo = (this.checkboxHalfday2 == true) ? '0.5' : '0';

        this.checkboxHalfday1 == false ? this.valueFromHalfday = '' : '';
        this.checkboxHalfday2 == false ? this.valueToHalfday = '' : '';

        
        axios.get(process.env.VUE_APP_API_URL + 'cuti/totaldays?datestart='+ this.valueFromDate + '&dateend=' + this.valueToDate + '&starttime=' + halfdayFrom + '&endtime=' + halfdayTo + '&leavetype=' + this.leaveData.leavetype, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {
            if (this.leaveData.leavetype != '2' && this.leaveData.leavetype != '1'){
                this.leaveData.countused = response.data.totaldays3;
            } else {
                this.leaveData.countused = response.data.totaldays;
            }            
        })
        .catch(error => {
            console.log(error);
        });
      },
      countDaysOtherLeave(leave_type){

        // let date = new Date( (new Date(this.valueFromDate).getTime() + ( (days - 1) * 24 * 60 * 60 * 1000) ) );
        // this.valueToDate = new Date(date).toISOString().substr(0, 10);
        // this.leaveData.countused = days;

        // console.log(leave_type);

        if(leave_type == '8') {
            this.valueToDate = this.valueFromDate;
            this.leaveData.countused = 1;
            this.valueMaxDate = '';
        } else {
            (leave_type == undefined) ? leave_type = this.leaveData.leavetype : ''
            axios.get(process.env.VUE_APP_API_URL + 'cuti/totaldays?datestart='+ this.valueFromDate + '&dateend=' + this.valueToDate + '&starttime=0&endtime=0&leavetype='+ leave_type, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.valueToDate = response.data.dateend2.split('T')[0];
                this.leaveData.countused = response.data.totaldays2;
                this.valueMaxDate = response.data.dateend2.split('T')[0];
                
            })
            .catch(error => {
                console.log(error);
            });
        }

        
      
      },

      fileUploadOnChange(file){
          
        this.fileUploadProgress = true;
        if (file == null) {
            this.leaveData.fileupload = '';
            this.fileUploadProgress = false;
        } else {

            const formData =  new FormData();

            formData.append('files', file);

            axios.post(process.env.VUE_APP_API_URL + 'employees/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.$store.getters.token,
            }
            })
            .then((response) => {
                // console.log(response.data);
                this.leaveData.fileupload = response.data.filepath;
                this.fileUploadProgress = false;
                
            })
            .catch(error => {
                console.log(error);
                this.fileUploadProgress = false;
            });
        }

      },

      resetForm () {
        this.$refs.anualLeaveForm.reset()
      },

      submitLeaveForm(){
          
        if(this.$refs.anualLeaveForm.validate() == true){

            if (this.fileUploadProgress == true) {
                this.messagetext = 'Please wait until your document is successfully uploaded!';
                this.messagecolor = 'error';
                this.snackbar = true;
            } else {
                let start_date = (this.valueFromHalfday == '') ? this.valueFromDate + 'T08:30:00' : (this.valueFromHalfday == 'PM (1.00pm - 05.00pm)') ? this.valueFromDate + 'T13:00:00' : this.valueFromDate + 'T08:30:00';

                let end_date;
                
                if(this.valueFromDate == this.valueToDate){
                    end_date = (this.valueFromHalfday == 'AM (8.30am - 01.00pm)') ? this.valueToDate + 'T13:00:00' : this.valueToDate + 'T17:00:00';
                } else {
                    end_date = (this.valueToHalfday == '') ? this.valueToDate + 'T17:00:00' : (this.valueToHalfday == 'AM (8.30am - 01.00pm)') ? this.valueToDate + 'T13:00:00' : this.valueToDate + 'T17:00:00';
                }

                const payload = {
                    idemp: this.$store.getters.user.idEmp,
                    idleavetype: this.leaveData.leavetype,
                    startdate: start_date,
                    enddatetime: end_date,
                    countused: this.leaveData.countused,
                    address: this.leaveData.address,
                    reason: this.leaveData.reason,
                    fileupload: this.leaveData.fileupload
                };

                axios.post(process.env.VUE_APP_API_URL + 'cuti/addcuti', payload, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token,
                    }
                })
                .then((response) => {
                    if(response.data.message == 'Leave successfully applied'){
                        this.applyLeaveDialog = false;
                        this.messagetext = response.data.message;
                        this.messagecolor = 'success';
                        this.snackbar = true;
                        this.updateEvent ();
                        bus.$emit('changeIt', 'loadNotify');
                        this.$refs.anualLeaveForm.reset();
                    } else {                    
                        console.log(response.data);
                    }            
                    
                    
                })
                .catch(error => {
                    this.messagetext = error.response.data.message;
                    this.snackbar = true;
                    this.messagecolor = 'error';
                });
            }
        } 
      }
    },
}
</script>

<style lang="scss" scoped>

</style>
