<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-calendar-cursor</v-icon>
            LEAVE
        </v-card-title>

        <!-- Looping List Tab -->
        <v-tabs
        v-model="tabs"
        >
            <v-tab v-for="item in tabsLeave" :key="item.tabName" class="tab-icon">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.tabName }}
            </v-tab>
        </v-tabs>

        <!-- Container Tabs -->
        <v-tabs-items v-model="tabs">

            <!-- Tab Inbox -->
            <v-tab-item class="container-tab-modul">
                <v-container>
                    <v-row dense>
                        <v-col cols="12" md="4">
                        <v-card
                            min-height="175"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    TOTAL LEAVE
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-icon x-large>mdi-calendar-blank</v-icon>
                            </v-list-item>
                            <!-- <v-container> -->
                                <v-row dense>
                                    <v-col cols="12" sm="6"  >
                                        <div class="text-center">
                                            <v-progress-circular
                                            :value="valueBakiCuti > -1? annualLeaveValue : (valueBakiCuti + (Math.abs(valueBakiCuti) * 2)) * 10"
                                            :color="valueBakiCuti > -1? 'cyan accent-4' : 'red'"
                                            >
                                                <span v-if="leaveDashboard == null" v-html="'0'" class="text-bold caption"></span>
                                                <span v-else v-html="valueBakiCuti" class="text-bold caption"></span>
                                            </v-progress-circular>                                            
                                        </div>
                                        <v-card-text class="pb-0 text-center">
                                            <!-- <v-icon class="mr-2" dense>mdi-airplane</v-icon> -->
                                            <span class="font-weight-bold">                                            
                                                Annual:
                                            </span> 
                                        </v-card-text>
                                        <v-card-text class="pt-0 text-center">
                                            <span v-if="leaveDashboard == null" v-html="'0 from 0'"></span>
                                            <span v-else>
                                                {{ (leaveDashboard.annualleaveused ? leaveDashboard.annualleaveused : 0) + ' from ' + (leaveDashboard.totalannualleave ? leaveDashboard.totalannualleave : 0) }}
                                                <v-tooltip max-width="450" bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                        color="primary"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        mdi-information
                                                        </v-icon>
                                                    </template>
                                                    <span>Total Annual Leave is the combination of Annual Leave Entitlements (based on years of service) and Carried Forward Leaves (from previous year - if any).</span>
                                                    <v-divider></v-divider>
                                                    <span v-if="new Date(Date.now()).getMonth() <= 3">
                                                        Total Carry Forward Leave: {{ leaveDashboard.countcarryforward }}
                                                    </span>
                                                </v-tooltip>
                                            </span>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <div class="text-center">
                                            <v-progress-circular
                                            :value="valueBakiMedical > -1? medicalLeaveValue : (valueBakiMedical + (Math.abs(valueBakiMedical) * 2)) * 10"
                                            :color="valueBakiMedical > -1? 'light-blue accent-3' : 'red'"
                                            >
                                                <span v-if="leaveDashboard == null" v-html="'0'" class="text-bold caption"></span>
                                                <span v-else v-html="valueBakiMedical" class="text-bold caption"></span>
                                            </v-progress-circular>                                      
                                        </div>
                                        <v-card-text class="pb-0 text-center">
                                            <!-- <v-icon class="mr-2" dense>mdi-medical-bag</v-icon> -->
                                            <span class="font-weight-bold">
                                                Medical:
                                            </span>
                                        </v-card-text>
                                        <v-card-text class="pt-0 text-center">
                                            <span v-if="leaveDashboard == null" v-html="'0 from 0'"></span>
                                            <span v-else v-html="(leaveDashboard.medicalleaveused ? leaveDashboard.medicalleaveused : 0) + ' from ' + (leaveDashboard.totalmedicalleave ? leaveDashboard.totalmedicalleave : 0)"></span>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            <!-- </v-container> -->
                        </v-card>
                        </v-col>
                        <v-col cols="12" md="8">
                        <v-card
                            min-height="175"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    OTHER LEAVE
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-icon x-large>mdi-calendar-blank</v-icon>
                            </v-list-item>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12" sm="6" v-for="item in otherLeaveVal" :key="item.leave_type">
                                        <v-card-text class="pa-0">
                                            <v-icon class="mr-2" dense>{{ item.icon }}</v-icon>
                                            <span class="font-weight-bold text-capitalize">{{ item.leave_type.toLowerCase() }}:</span>
                                            {{ item.value }}
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <template>
                    <v-container>
                        <v-data-table
                        :headers="headers"
                        :items="filteredLeaves"
                        :sort-by="['dateapplied']"
                        :sort-asc="[true]"
                        class="elevation-2"
                        :search="search"
                        :custom-sort="customSort"
                        >
                            <template v-slot:[`header.dateapplied`]="{ header }">
                                {{ header.text }}
                                <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small v-if="appliedDateFilter == ''">
                                            mdi-filter
                                        </v-icon>
                                        <v-icon small v-else>
                                            mdi-filter-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card style="width: 280px" class="pa-2">
                                    <v-card-text class="pa-0">
                                        <v-text-field
                                        v-model="appliedDateFilter"
                                        label="Date Applied"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        @click="appliedDateFilter = ''"
                                        small
                                        outlined
                                        color="primary"
                                        class="mt-2"
                                        >Clear</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-menu>
                            </template>

                            <template v-slot:[`header.leavetype`]="{ header }">
                                {{ header.text }}
                                <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small v-if="leaveTypeFilter == ''">
                                            mdi-filter
                                        </v-icon>
                                        <v-icon small v-else>
                                            mdi-filter-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card style="width: 280px" class="pa-2">
                                    <v-card-text class="pa-0">
                                        <v-select
                                        v-model="leaveTypeFilter"
                                        :items="leaveType"
                                        label="Leave Type"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        @click="leaveTypeFilter = ''"
                                        small
                                        outlined
                                        color="primary"
                                        class="mt-2"
                                        >Clear</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:[`header.startdate`]="{ header }">
                                {{ header.text }}
                                <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small v-if="startDateFilter == ''">
                                            mdi-filter
                                        </v-icon>
                                        <v-icon small v-else>
                                            mdi-filter-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card style="width: 280px" class="pa-2">
                                    <v-card-text class="pa-0">
                                        <v-text-field
                                        v-model="startDateFilter"
                                        label="Start Date"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        @click="startDateFilter = ''"
                                        small
                                        outlined
                                        color="primary"
                                        class="mt-2"
                                        >Clear</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-menu>
                            </template>

                            <template v-slot:[`header.enddatetime`]="{ header }">
                                {{ header.text }}
                                <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small v-if="endDateFilter == ''">
                                            mdi-filter
                                        </v-icon>
                                        <v-icon small v-else>
                                            mdi-filter-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card style="width: 280px" class="pa-2">
                                    <v-card-text class="pa-0">
                                        <v-text-field
                                        v-model="endDateFilter"
                                        label="End Date"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        @click="endDateFilter = ''"
                                        small
                                        outlined
                                        color="primary"
                                        class="mt-2"
                                        >Clear</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:[`header.leavestatus`]="{ header }">
                                {{ header.text }}
                                <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small v-if="leaveStatusFilter == ''">
                                            mdi-filter
                                        </v-icon>
                                        <v-icon small v-else>
                                            mdi-filter-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card style="width: 280px" class="pa-2">
                                    <v-card-text class="pa-0">
                                        <v-select
                                        v-model="leaveStatusFilter"
                                        :items="approvalStatus"
                                        label="Leave Approval Status"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        @click="leaveStatusFilter = ''"
                                        small
                                        outlined
                                        color="primary"
                                        class="mt-2"
                                        >Clear</v-btn>
                                    </v-card-actions>                                
                                </v-card>
                                </v-menu>
                            </template>
                            
                            <!-- <template v-slot:[`item.dateapplied`]="{ item }">
                                <span>{{ convertDateTime(item.dateapplied) }}</span>
                            </template>

                            <template v-slot:[`item.startdate`]="{ item }">
                                <span>{{ convertDateTime(item.startdate) }}</span>
                            </template>

                            <template v-slot:[`item.enddatetime`]="{ item }">
                                <span>{{ convertDateTime(item.enddatetime) }}</span>
                            </template> -->

                            <template v-slot:[`item.leavestatus`]="{ item }">
                                <v-chip
                                    :color="getColor(item.leavestatus)"
                                    dark
                                >
                                    {{ item.leavestatus }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.detail`]="props">
                                <template>
                                    <v-icon dense class="primary--text" @click="editUserItem(props.item)">mdi-file-document</v-icon>
                                </template>
                            </template>

                            <template v-slot:top>
                                <v-dialog
                                v-model="detailsDialog"
                                max-width="750px"
                                scrollable
                                >
                                    <v-card>
                                        <v-card-title class="justify-center title-bar-modul">
                                            <h4 class="text-capitalize">{{ detailsLeave.leavetype.toLowerCase() }} Form</h4>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-simple-table>
                                                <colgroup>
                                                    <col span="1" style="width: 30%;">
                                                    <col span="1" style="width: 70%;">
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-subtitle-1 text-bold grey--text text-center" colspan="2"
                                                        >
                                                        Leave Information
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Applicant Name:</td>

                                                        <td>
                                                            {{ detailsLeave.name }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Leave Type:</td>

                                                        <td>
                                                            {{ detailsLeave.leavetype }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Start Date:</td>

                                                        <td>
                                                            {{ detailsLeave.startdate }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">End Date:</td>

                                                        <td>
                                                            {{ detailsLeave.enddatetime }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">No of Days Applied:</td>

                                                        <td>
                                                            {{ detailsLeave.countused }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Address During Leave:</td>

                                                        <td>
                                                            {{ detailsLeave.address }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Reason:</td>

                                                        <td>
                                                            {{ detailsLeave.reason }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="detailsLeave.leavetype == 'MEDICAL LEAVE'">
                                                        <td class="font-weight-bold">Medical Certificate:</td>

                                                        <!-- <td v-if="detailsLeave.fileupload == ''">
                                                            <v-file-input
                                                                label="Upload Medical Certificate"
                                                                outlined
                                                                dense
                                                                hide-details="auto"
                                                                @change="uploadMC($event)"
                                                            ></v-file-input>
                                                        </td> -->
                                                        <td>
                                                            <v-btn
                                                                class="ma-0"
                                                                color="primary"
                                                                dark
                                                                small
                                                                @click="viewMC(detailsLeave.fileupload)"
                                                            >
                                                                <v-icon dark small>
                                                                mdi-file-document-outline
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                
                                            </v-simple-table>
                                            <v-divider></v-divider>

                                            <v-simple-table>
                                                <colgroup>
                                                    <col span="1" style="width: 30%;">
                                                    <col span="1" style="width: 70%;">
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-subtitle-1 text-bold grey--text text-center" colspan="2"
                                                        >
                                                        Approval Information
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Submission Date:</td>

                                                        <td>
                                                            {{ detailsLeave.dateapplied }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Leave Aproval Status:</td>

                                                        <td>
                                                            <span 
                                                            v-if="detailsLeave.leavestatus == 'PENDING'" 
                                                            class="orange--text">
                                                                <strong>{{ detailsLeave.leavestatus }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.leavestatus == 'APPROVED'" 
                                                            class="green--text">
                                                                <strong>{{ detailsLeave.leavestatus }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.leavestatus == 'REJECTED'" 
                                                            class="red--text">
                                                                <strong>{{ detailsLeave.leavestatus }}</strong>
                                                            </span>
                                                            <span v-else class="pink--text text--darken-2">
                                                                {{ detailsLeave.leavestatus }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Remarks:</td>

                                                        <td>
                                                            {{ detailsLeave.remask }}
                                                        </td>
                                                    </tr>

                                                    <!-- comment sat -->

                                                    <!-- <tr>
                                                        <td class="font-weight-bold">Immediate Supervisor:</td>

                                                        <td>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Supervisor Aproval Status:</td>

                                                        <td                                <span 
                                                            v-if="detailsLeave.supervisorapproved == 'PENDING'" 
                                                            class="orange--text">
                                                                <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.supervisorapproved == 'APPROVED'" 
                                                            class="green--text">
                                                                <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.supervisorapproved == 'REJECTED'" 
                                                            class="red--text">
                                                                <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                            </span>                                                        
                                                        </v-list-item-subtitle>
                                                    </tr> -->

                                                    <!-- end of comment sat -->

                                                    <!-- <tr>                                                    
                                                        <td class="font-weight-bold">Aproval Date:</td>

                                                        <td>
                                                            
                                                        </v-list-item-subtitle>
                                                    </tr> -->

                                                    <!-- comment sat -->
                                                    <!-- <tr>
                                                        <td class="font-weight-bold">Superior:</td>

                                                        <td>
                                                            
                                                        </v-list-item-subtitle>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Superior Approval Status:</td>

                                                        <td                                <span 
                                                            v-if="detailsLeave.superiorapproved == 'PENDING'" 
                                                            class="orange--text">
                                                                <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.superiorapproved == 'APPROVED'" 
                                                            class="green--text">
                                                                <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.superiorapproved == 'REJECTED'" 
                                                            class="red--text">
                                                                <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                            </span>
                                                        </v-list-item-subtitle>
                                                    </tr> -->
                                                    <!-- end of comment sat -->

                                                    <!-- <tr>
                                                        <td class="font-weight-bold">Approval Date:</td>

                                                        <td>
                                                            
                                                        </v-list-item-subtitle>
                                                    </tr> -->

                                                    <!-- comment sat -->

                                                    <!-- <tr>
                                                        <td class="font-weight-bold">Remarks:</td>

                                                        <td>
                                                            
                                                        </v-list-item-subtitle>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Emergency Leave:</td>

                                                        <td>
                                                            
                                                        </v-list-item-subtitle>
                                                    </tr> -->
                                                    <!-- end of comment sat -->

                                                </tbody>
                                            </v-simple-table>

                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                outlined
                                                @click="close"
                                            >
                                                Close
                                            </v-btn>
                                            <v-btn
                                                v-if="submitButtonMC == true"
                                                color="primary"
                                                outlined
                                                @click="updateMC()"
                                            >
                                                Submit
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>

                        </v-data-table>
                    </v-container>
                </template>
                
                <!-- Snackbar for upload MC -->
                <template>
                    <div class="text-center">
                        <v-snackbar
                        v-model="snackbar"
                        :timeout="timeout"
                        :color="messagecolor"
                        >
                        {{ messagetext }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                            >
                            Close
                            </v-btn>
                        </template>
                        </v-snackbar>
                    </div>
                </template>

            </v-tab-item>

            <!-- Tab Calendar -->
            <v-tab-item class="container-tab-modul">
                <v-card>
                    <v-card-text>
                        <ul class="bullet-icon">

                            <li>
                            <v-icon class="mr-2" small>mdi-calendar-alert</v-icon>
                                Leave application must be submitted at least <strong>five (5) working days</strong> prior to the intended leave. If less 5 days, considered as <strong>EMERGENCY LEAVE</strong>.
                            </li>

                            <li>
                            <v-icon class="mr-2" small>mdi-fraction-one-half</v-icon>
                            An employee is allowed to take HALF DAY LEAVE which is deducted from an employee's annual leave eligibitiy.
                            </li>

                            <li>
                            <v-icon class="mr-2" small>mdi-medical-bag</v-icon>
                            Original Medical Certificate <strong>MUST</strong> be presented to HR and uploaded in MEMS.
                            </li>

                        </ul>
                    </v-card-text>
                </v-card>

                <!-- Monthly Calander -->                
                <calendar-leave>

                </calendar-leave>
                <!-- End of Monthly Calander -->

            </v-tab-item>

        </v-tabs-items>



    </v-container>
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';
import Calendar from '@/components/leave/Calendar';


export default {
    
    components: {
        'calendar-leave': Calendar,
    },
    data: () => ({

        tabs: null,
        tabsLeave: [
            {tabName: "Inbox", icon: "mdi-inbox-multiple" },
            {tabName: "Apply Leave", icon: "mdi-calendar-multiselect" },
        ],
        search: '',
        calories: '',
        leaves: [],
        detailsLeave : {
            idemp: '',
            dateapplied: '',
            leavetype: '',
            startdate: '',
            enddatetime: '',
            supervisorapproved: '',
            superiorapproved: '',
            leavestatus: '',
            totalannualleave: '',
            annualleaveadjustment: '',
            annualleaveused: '',
            totalmedicalleave: '',
            medicalleaveused: '',
            address: '',
            reason: '',
            fileupload: '',
            status: '',
            remark: ''
        },
        detailsDialog: false,
        checkbox1: true,
        annualLeave: false,
        otherLeave: false,

        submitButtonMC: false,
        uploadMCData: {
            startdt: '',
            enddt: '',
            fileupload: ''
        },
        
        leaveType: [],
        approvalStatus: ['PENDING', 'REJECTED','APPROVED'],
        leaveTypeFilter: '',
        appliedDateFilter: '',
        startDateFilter: '',
        endDateFilter: '',
        leaveStatusFilter: '',
        leaveDashboard: '',
        annualLeaveValue: '',
        medicalLeaveValue: '',
        otherLeaveVal: [
            {
                leave_type: 'BEREAVEMENT LEAVE',
                value: 0,
                icon: 'mdi-home-heart'
            },
            {
                leave_type: 'MARRIAGE LEAVE',
                value: 0,
                icon: 'mdi-human-male-female'
            },
            {
                leave_type: 'MATERNITY LEAVE',
                value: 0,
                icon: 'mdi-human-female-boy'
            },
            {
                leave_type: 'PATERNITY LEAVE',
                value: 0,
                icon: 'mdi-human-male-child'
            },
            {
                leave_type: 'HOSPITALIZATION LEAVE',
                value: 0,
                icon: 'mdi-hospital-building'
            },
            {
                leave_type: 'REPLACEMENT LEAVE',
                value: 0,
                icon: 'mdi-calendar'
            },
        ],
        
        // Snackbar message
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',

    }),
    computed: {
        headers () {
            return [
            // {
            //     text: 'Dessert (100g serving)',
            //     align: 'start',
            //     sortable: false,
            //     value: 'name',
            // },
            // {
            //     text: 'Calories',
            //     value: 'calories',
            //     filter: value => {
            //     if (!this.calories) return true

            //     return value < parseInt(this.calories)
            //     },
            // },
                { text: 'Date Applied', value: 'dateapplied'},
                { text: 'Leave Type', value: 'leavetype' },
                { text: 'Start Date', value: 'startdate' },
                { text: 'End Date', value: 'enddatetime' },
                { text: 'Leave Approval Status', value: 'leavestatus' },
                { text: 'Remarks', value: 'remark' },
                { text: 'Detail', value: 'detail', sortable: false, align:'center'},
            ]
        },
        
        valueBakiCuti(){
            let value = (this.leaveDashboard.totalannualleave ? this.leaveDashboard.totalannualleave : 0) - (this.leaveDashboard.annualleaveused ? this.leaveDashboard.annualleaveused : 0)
            return value;
        },

        valueBakiMedical(){
            let value = (this.leaveDashboard.totalmedicalleave ? this.leaveDashboard.totalmedicalleave : 0) - (this.leaveDashboard.medicalleaveused ? this.leaveDashboard.medicalleaveused : 0)
            return value;
        },

        itemsWithIndex() {
            return this.leaves.map(
                (leaves, index) => ({
                ...leaves,
                index: index + 1
                }))
        },
        filteredLeaves() {
      
            let conditions = [];
            
            if (this.leaveTypeFilter) {
                conditions.push(this.filterLeaveType);
            }
            if (this.leaveStatusFilter) {
                conditions.push(this.filterLeaveStatus);
            }
            if (this.startDateFilter) {
                conditions.push(this.filterStartDate);
            }
            if (this.endDateFilter) {
                conditions.push(this.filterEndDate);
            }
            if (this.appliedDateFilter) {
                conditions.push(this.filterAppliedDate);
            }
            
            if (conditions.length > 0) {
                return this.leaves.filter((leave) => {
                return conditions.every((condition) => {
                    return condition(leave);
                })
                })
            }
            
            return this.leaves;
        }
    },
    methods: {
        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            
            items.sort((a, b) => {
                if (index[0]=='dateapplied' || index[0]=='startdate' || index[0]=='enddatetime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        },

        getLeaveDashboard(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=3&id='+this.$store.getters.user.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.leaveDashboard = response.data.info;
                
                if(this.leaveDashboard != null ){
                    // this.annualLeaveValue = (parseFloat(this.leaveDashboard.annualleaveused) / parseFloat(this.leaveDashboard.totalannualleave)) * 100;
                    this.annualLeaveValue = ((parseFloat(this.leaveDashboard.totalannualleave) - parseFloat(this.leaveDashboard.annualleaveused)) / parseFloat(this.leaveDashboard.totalannualleave)) * 100;
                
                    // this.medicalLeaveValue = (parseFloat(this.leaveDashboard.medicalleaveused) / parseFloat(this.leaveDashboard.totalmedicalleave)) * 100;
                    this.medicalLeaveValue = ((parseFloat(this.leaveDashboard.totalmedicalleave) - parseFloat(this.leaveDashboard.medicalleaveused)) / parseFloat(this.leaveDashboard.totalmedicalleave)) * 100;
                } else {
                    this.annualLeaveValue = parseInt(0);
                    this.medicalLeaveValue = parseInt(0);
                }
                

            })
            .catch(error => {
                console.log(error);
            })
        },
        getAllLeave(){
            axios.get(process.env.VUE_APP_API_URL + 'cuti/all?self=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                for(let i = 0; i < response.data.length; i++){
                    response.data[i].dateapplied = this.convertDateTime(response.data[i].dateapplied);
                    response.data[i].startdate = this.convertDateTime(response.data[i].startdate);
                    response.data[i].enddatetime = this.convertDateTime(response.data[i].enddatetime);
                }

                this.leaves = response.data;

                // console.log(this.leaves);

                const leaves = response.data;
                const leaveVal =  [
                    {
                        leave_type: 'BEREAVEMENT LEAVE',
                        value: 0,
                        icon: 'mdi-home-heart'
                    },
                    {
                        leave_type: 'MARRIAGE LEAVE',
                        value: 0,
                        icon: 'mdi-human-male-female'
                    },
                    {
                        leave_type: 'MATERNITY LEAVE',
                        value: 0,
                        icon: 'mdi-human-female-boy'
                    },
                    {
                        leave_type: 'PATERNITY LEAVE',
                        value: 0,
                        icon: 'mdi-human-male-child'
                    },
                    {
                        leave_type: 'HOSPITALIZATION LEAVE',
                        value: 0,
                        icon: 'mdi-hospital-building'
                    },
                    {
                        leave_type: 'REPLACEMENT LEAVE',
                        value: 0,
                        icon: 'mdi-calendar'
                    },
                ];

                for(let i = 0; i < leaves.length; i++){
                    for(let k in leaveVal){
                        if(leaveVal[k].leave_type == leaves[i].leavetype){
                            if(leaves[i].leavestatus == 'APPROVED'){
                                leaveVal[k].value = parseFloat(leaveVal[k].value) + parseFloat(leaves[i].countused);
                            }
                        }
                    }
                }

                this.otherLeaveVal = leaveVal;
            })
            .catch(error => {
                console.log(error);
            })
        },
        getColor (leavestatus) {
            if (leavestatus == 'REJECTED') return 'red'
            else if (leavestatus == 'PENDING') return 'orange'
            else if (leavestatus == 'APPROVED') return 'green'
            else return 'pink darker-2'
        },
        editUserItem(item) {
            this.editedIndex = this.leaves.indexOf(item)
            this.detailsLeave = Object.assign({}, item)
            this.detailsDialog = true
        },
        close () {
            this.detailsDialog = false
        },
        viewMC(link){
            if(link != null && link != ''){
                window.open(
                    link,
                    '_blank'
                );
            } else {
                this.messagetext = 'The medical certificate is not available.'
                this.messagecolor = 'error'
                this.snackbar = true
            }
        },
        uploadMC(file){

            const formData =  new FormData();

            formData.append('files', file);

            axios.post(process.env.VUE_APP_API_URL + 'employees/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                console.log(response.data);
                this.uploadMCData.fileupload = response.data.filepath;
                this.submitButtonMC = true;
                
            })
            .catch(error => {
                console.log(error);
            });
        },
        updateMC(){
            
            const payload = {
                startdt: this.detailsLeave.startdate,
                enddt: this.detailsLeave.enddatetime,
                fileupload: this.uploadMCData.fileupload
            };

            axios.put(process.env.VUE_APP_API_URL + 'cuti/editcuti', payload, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                console.log(response.data);
                this.detailsDialog = false;
                this.messagetext = 'Your Medical Certificate is successfully uploaded!';
                this.messagecolor = 'success'
                this.snackbar = true;
                this.getAllLeave();         
            })
            .catch(error => {
                console.log(error);
            });
        },
        getDropdownList(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                const leavetype = response.data.leavetype;
                
                for(let i = 0; i < leavetype.length; i++){
                    this.leaveType.push(leavetype[i].name);
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
        filterLeaveType(item) {
            return item.leavetype.includes(this.leaveTypeFilter);
        },
        filterAppliedDate(item) {
            return item.dateapplied.toLowerCase().includes(this.appliedDateFilter.toLowerCase());
        },
        filterStartDate(item) {
            return item.startdate.toLowerCase().includes(this.startDateFilter.toLowerCase());
        },
        filterEndDate(item) {
            return item.enddatetime.toLowerCase().includes(this.endDateFilter.toLowerCase());
        },
        filterLeaveStatus(item) {
            return item.leavestatus.includes(this.leaveStatusFilter);
        },
    },
    mounted() {
        this.getLeaveDashboard();
        this.getAllLeave();
        this.getDropdownList();
    },

    

    created (){
        bus.$on('changeIt', (data) => {

            if (data == "loadNotify") {
                this.getAllLeave();
            }

        })
    }
}


</script>

<style lang="scss" scoped>
@import '~scss/main';

.overflow-visible {
    white-space: normal !important;
}

.details-title {
    max-width: 200px;
}

</style>
